import { DocumentSnapshot } from "firebase/firestore";

export interface IUser {
  id: string;
  email: string;
  token?: string;
  channels: string[];
}

export class User implements IUser {
  public id!: string;
  public email!: string;
  public token?: string;
  public channels!: string[];

  constructor(param: IUser) {
    Object.assign(this, param);
  }

  public static fromFirestore(doc: DocumentSnapshot): User {
    const data = doc.data();
    if (!data) throw new Error("document data is undefined");
    return new User({
      id: doc.id,
      email: data.email,
      token: data.token,
      channels: data.channels || [],
    });
  }
}
