import { useCallback, useContext, useEffect, useState } from "react";
import { TextField, List, Divider } from "@mui/material";
import { IconBrandYoutube } from "@tabler/icons-react";
import Functions from "../../../callable/functions";
import { Channel } from "../../../models/channel";
import ChannelDb from "../../../db/channelDb";
import { getDocs } from "firebase/firestore";
import { UserContext } from "../../../contexts/userContext";
import CircularProgress from "@mui/material/CircularProgress";
import CustomButton from "../../utils/customButton";

const functions = new Functions();

export default function SettingsScreen() {
  const user = useContext(UserContext);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [myChannels, setMyChannels] = useState<Channel[]>([]);
  const [trackedChannels, setTrackedChannels] = useState<Channel[]>([]);
  const [newChannel, setNewChannel] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [addingChannel, setAddingChannel] = useState(false);

  const fetchChannels = useCallback(
    async (retries = 1) => {
      if (user) {
        setLoading(true);
        const channelDb = new ChannelDb();
        const querySnapshot = await getDocs(
          channelDb.collection({ userId: user.id, channelId: "" })
        );
        const channelsData = querySnapshot.docs.map(
          (doc) => doc.data() as Channel
        );

        setChannels(channelsData);

        const myChannels = channelsData.filter((channel) => channel.mine);
        const trackedChannels = channelsData.filter((channel) => !channel.mine);

        setMyChannels(myChannels);
        setTrackedChannels(trackedChannels);
        setLoading(false);
      }
    },
    [user]
  );

  useEffect(() => {
    fetchChannels();
  }, [fetchChannels]);

  const handleAddChannel = async () => {
    if (!newChannel.trim() || !user) return;
    setAddingChannel(true);

    try {
      await functions.addChannel({
        userId: user.id,
        channelIdentifier: newChannel.trim(),
      });
      setNewChannel("");
      fetchChannels();
    } catch (error) {
      console.error("Error adding channel:", error);
    } finally {
      setAddingChannel(false);
    }
  };

  return (
    <div className="w-full min-h-screen bg-[#0B0B0F] text-white p-2 md:px-32 md:py-8 font-custom">
      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <CircularProgress color="inherit" size={20} />
        </div>
      ) : (
        <>
          {/* Channels Section */}
          <h4 className="text-base mb-2 font-custom font-bold">Channels</h4>
          <Divider
            sx={{
              borderColor: "#282828",
              borderBottomWidth: 2,
              marginBottom: 2,
            }}
          />

          {/* My Channels */}
          <h5 className="text-sm mt-4 mb-2 font-bold">My Channels</h5>
          <List>
            {myChannels.map((channel, index) => (
              <div className="flex items-center space-x-4" key={index}>
                {channel.thumbnails?.default?.url ? (
                  <img
                    src={channel.thumbnails.default.url}
                    alt={channel.title}
                    className="w-6 h-6 rounded-full"
                  />
                ) : (
                  <IconBrandYoutube size={20} className="text-[#FF0000]" />
                )}
                <span className="text-base font-custom font-bold">
                  {channel.title}
                </span>
              </div>
            ))}
          </List>

          {/* Tracking Channels */}
          <h5 className="text-sm mt-4 mb-2 font-bold">Tracking Channels</h5>
          <List className="space-y-4">
            {trackedChannels.map((channel, index) => (
              <div className="flex items-center space-x-4" key={index}>
                {channel.thumbnails?.default?.url ? (
                  <img
                    src={channel.thumbnails.default.url}
                    alt={channel.title}
                    className="w-6 h-6 rounded-full"
                  />
                ) : (
                  <IconBrandYoutube size={20} className="text-[#FF0000]" />
                )}
                <span className="text-base font-custom font-bold">
                  {channel.title}
                </span>
              </div>
            ))}
          </List>

          {/* Add Channel Input */}
          <div className="flex items-center mt-4">
            <TextField
              variant="standard"
              placeholder="Channel ID or @username"
              value={newChannel}
              onChange={(e) => setNewChannel(e.target.value)}
              className="mr-4"
              InputProps={{
                style: {
                  fontFamily: "'YouTubeSansRegular', sans-serif",
                  color: "white",
                },
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#282828",
                },
                "& .MuiInput-underline:hover:before": {
                  borderBottomColor: "#282828",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#282828",
                },
                width: "50%",
              }}
            />
            <CustomButton
              onClick={handleAddChannel}
              disabled={addingChannel}
              loading={addingChannel}
              loadingText="Adding..."
            >
              Add
            </CustomButton>
          </div>
        </>
      )}
    </div>
  );
}
