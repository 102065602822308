import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconMessages,
  IconLogout,
  IconSettings,
  IconGraph,
  IconSpeakerphone,
  IconPhoto,
} from "@tabler/icons-react";
import { getAuth, signOut } from "firebase/auth";

export default function SideBar() {
  const navigate = useNavigate();

  const handleOnClick = useCallback(
    (path: string) => navigate(`/app${path}`),
    [navigate]
  );

  const handleLogout = () => {
    signOut(getAuth());
    navigate(`/`);
  };

  return (
    <div className="bg-[#0B0B0F] h-screen w-20 flex flex-col items-center py-6 space-y-6 border-r border-[#282828] fixed top-0 left-0">
      <button onClick={() => handleOnClick("")}>
        <img src="/images/logo.svg" alt="Logo" className="h-8 mb-8" />
      </button>
      <button onClick={() => handleOnClick("")}>
        <IconMessages
          size={24}
          strokeWidth={1}
          className="text-[#FFFFFF] mb-6"
        />
      </button>
      <button onClick={() => handleOnClick("/analytics")}>
        <IconGraph size={24} strokeWidth={1} className="text-[#FFFFFF] mb-6" />
      </button>
      <button onClick={() => handleOnClick("/campaigns")}>
        <IconSpeakerphone
          size={24}
          strokeWidth={1}
          className="text-[#FFFFFF] mb-6"
        />
      </button>
      <button onClick={() => handleOnClick("/thumbs")}>
        <IconPhoto size={24} strokeWidth={1} className="text-[#FFFFFF] mb-6" />
      </button>
      <button onClick={() => handleOnClick("/settings")}>
        <IconSettings
          size={24}
          strokeWidth={1}
          className="text-[#FFFFFF] mb-6"
        />
      </button>
      <div className="flex-grow" />
      <button onClick={handleLogout}>
        <IconLogout size={24} strokeWidth={1} className="text-[#FFFFFF]" />
      </button>
    </div>
  );
}
