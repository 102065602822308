import React from "react";
import { useNavigate } from "react-router-dom";
import { GoogleAuthProvider } from "firebase/auth";
import { signInWithProvider } from "../../contexts/userContext";
import {
  IconBrandGoogleFilled,
  IconMessageCircle2,
  IconBrandOpenai,
  IconChartBar,
  IconTrendingUp,
  IconSpeakerphone,
  IconCalendar,
  IconPhoto,
} from "@tabler/icons-react";
import CustomButton from "../utils/customButton";

export default function LandingPage() {
  const navigate = useNavigate();
  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    await signInWithProvider(provider).then(() => navigate("/app/thumbs"));
  };

  return (
    <div
      className="min-h-screen text-white flex flex-col items-center justify-center px-4 py-8 font-custom"
      style={{
        backgroundImage: "url(/images/background-pattern.png)",
        backgroundPosition: "top center",
      }}
    >
      <header className="w-full max-w-5xl flex justify-between items-center px-8 py-4 mx-auto">
        <img src="/images/logo.svg" alt="Logo" className="h-8" />
      </header>
      <main className="text-center mt-12 max-w-3xl mx-auto px-4">
        <h1 className="text-2xl font-customBold font-bold mb-6 md:text-4xl">
          Boost your YouTube channel and outsmart the competition with YouGenie.
        </h1>
        <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
          <CustomButton
            variant="contained"
            startIcon={<IconCalendar size={20} />}
            onClick={() =>
              window.open(
                "https://calendar.app.google/mU2hf2jVRsCs5dBNA",
                "_blank"
              )
            }
            sx={{
              backgroundColor: "#FF0000",
              "&:hover": { backgroundColor: "#CC0000" },
            }}
          >
            Book a Demo
          </CustomButton>
          <CustomButton
            startIcon={<IconBrandGoogleFilled size={20} />}
            onClick={signInWithGoogle}
          >
            Sign in with Google
          </CustomButton>
        </div>
        <div className="mt-16">
          <img
            src="/images/laptop.png"
            alt="Laptop"
            className="w-full max-w-4xl mx-auto"
          />
        </div>
        <section className="mt-16 text-left">
          <h2 className="text-xl font-bold mb-8 md:text-2xl">Features</h2>
          <div className="space-y-8">
            <div className="flex items-start space-x-4">
              <IconPhoto size={48} className="text-[#FF0000]" />
              <div>
                <h3 className="font-bold mb-2">
                  AI-Powered Thumbnail Generator
                </h3>
                <p className="text-gray-300">
                  Create eye-catching thumbnails with our advanced AI-powered
                  generator. Boost your click-through rates and stand out from
                  the competition with custom-tailored thumbnails for your
                  videos.
                </p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <IconBrandOpenai size={48} className="text-[#FF0000]" />
              <div>
                <h3 className="font-bold mb-2">AI-Powered Engagement</h3>
                <p className="text-gray-300">
                  Generate intelligent replies that improve over time, and
                  extract valuable insights from comments on your channel and
                  competitors' channels using advanced AI prompts.
                </p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <IconMessageCircle2 size={48} className="text-[#FF0000]" />
              <div>
                <h3 className="font-bold mb-2">Competitor Tracking</h3>
                <p className="text-gray-300">
                  Monitor competitors' channels, analyze their comments, and
                  engage seamlessly to expand your presence and gain a
                  competitive edge.
                </p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <IconChartBar size={48} className="text-[#FF0000]" />
              <div>
                <h3 className="font-bold mb-2">Comprehensive Analytics</h3>
                <p className="text-gray-300">
                  Track key metrics like subscribers, views, and video
                  performance. Get insights into your channel's growth,
                  engagement, and content strategy effectiveness.
                </p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <IconTrendingUp size={48} className="text-[#FF0000]" />
              <div>
                <h3 className="font-bold mb-2">Performance Optimization</h3>
                <p className="text-gray-300">
                  Identify standout videos with our outlier detection, monitor
                  Views Per Hour (VPH), and optimize your content strategy for
                  better performance.
                </p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <IconSpeakerphone size={48} className="text-[#FF0000]" />
              <div>
                <h3 className="font-bold mb-2">Broadcast Campaigns</h3>
                <p className="text-gray-300">
                  Create and manage targeted broadcast campaigns to engage with
                  your audience across multiple videos. Boost interaction and
                  drive engagement with personalized messages.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="w-full max-w-5xl mx-auto mt-16 py-4 text-center">
        <div className="flex justify-center space-x-4">
          <a href="/pricing" className="text-gray-300 hover:text-white">
            Pricing
          </a>
          <a href="/privacy" className="text-gray-300 hover:text-white">
            Privacy Policy
          </a>
        </div>
      </footer>
    </div>
  );
}
