import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="bg-custom-bg text-white font-custom p-8 flex justify-center">
      <div className="max-w-2xl">
        <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
        <p className="mb-4">
          This Privacy Policy explains how PENGWIN DESENVOLVIMENTO E APOIO A
          NEGOCIOS LTDA ("we", "our", or "us") collects, uses, and protects your
          information when you use YouGenie. We are committed to protecting your
          privacy and ensuring that your personal information is handled in a
          safe and responsible manner.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">
          1. Company Information
        </h2>
        <p className="mb-4">
          <strong>Legal Name:</strong>
          <br />
          PENGWIN DESENVOLVIMENTO E APOIO A NEGOCIOS LTDA
        </p>
        <p className="mb-4">
          <strong>Address:</strong>
          <br />
          Rua dos Timbiras, 1925
          <br />
          Sala 903, CEP: 30.140-069
          <br />
          Bairro Lourdes, Belo Horizonte, Minas Gerais
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">2. Data Collection</h2>
        <p className="mb-4">
          <strong>Personal Data Collected:</strong>
          <br />
          We collect your email address through Google sign-in. Additionally,
          via YouTube's API, we collect channel information such as the channel
          title, thumbnail, subscriber count, view count, and video data
          including video titles, view counts, and engagement metrics. We also
          collect and analyze comments on your videos and competitors' videos.
          For broadcast campaigns, we collect campaign-related data such as
          campaign names, messages, and target videos. For the Thumbnail
          Generator feature, we collect and store generated thumbnail images and
          associated prompts.
        </p>
        <p className="mb-4">
          <strong>Methods of Collection:</strong>
          <br />
          Google sign-in, YouTube's API, user input for campaigns and thumbnail
          generation
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">3. Data Usage</h2>
        <p className="mb-4">
          We use the collected data to:
          <ul className="list-disc list-inside ml-4">
            <li>Provide a personalized experience for users.</li>
            <li>Improve our services based on user data.</li>
            <li>Generate AI-powered replies that get better over time.</li>
            <li>Track and monitor comments on competitors' channels.</li>
            <li>Provide analytics and insights about channel performance.</li>
            <li>
              Calculate and display metrics such as Views Per Hour (VPH) and
              outlier scores for videos.
            </li>
            <li>Analyze video comments using AI-powered tools.</li>
            <li>Create and manage broadcast campaigns for user engagement.</li>
            <li>
              Track the performance and effectiveness of broadcast campaigns.
            </li>
            <li>
              Generate and store AI-powered thumbnails based on user prompts.
            </li>
          </ul>
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">4. Data Sharing</h2>
        <p className="mb-4">
          We do not share your personal data with any third parties, including
          AI models.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">5. User Rights</h2>
        <p className="mb-4">
          <strong>Data Deletion:</strong>
          <br />
          Users can request the deletion of their data by contacting us via
          email at robertovclopes@gmail.com.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">6. Data Security</h2>
        <p className="mb-4">
          We employ Google Cloud Platform and adhere to best practices to ensure
          the security of your data.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">
          7. Cookies and Tracking
        </h2>
        <p className="mb-4">
          We do not use cookies or other tracking technologies.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">8. Data Retention</h2>
        <p className="mb-4">
          We retain personal data, channel analytics, and campaign data for as
          long as the user has an active account with us. Historical data may be
          retained for a period of time to provide trend analysis and
          performance comparisons.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">
          9. Third-Party Links
        </h2>
        <p className="mb-4">
          Our app includes links to YouTube videos and embedded YouTube players.
          These links and embeds are publicly accessible and subject to
          YouTube's own privacy policy.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">
          10. Children's Privacy
        </h2>
        <p className="mb-4">We do not collect data from children.</p>

        <h2 className="text-xl font-semibold mt-6 mb-2">11. Policy Changes</h2>
        <p className="mb-4">
          We will inform users of any changes to this Privacy Policy via email.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">
          12. Contact Information
        </h2>
        <p className="mb-4">
          For any questions or concerns regarding your privacy, please contact
          Roberto at robertovclopes@gmail.com.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">
          13. Compliance with Google API Services User Data Policy
        </h2>
        <p className="mb-4">
          YouGenie's use and transfer to any other app of information received
          from Google APIs will adhere to the{" "}
          <a
            href="https://developers.google.com/terms/api-services-user-data-policy"
            className="text-blue-500 underline"
          >
            Google API Services User Data Policy
          </a>
          , including the Limited Use requirements. This includes the usage of
          YouTube API Services.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">
          14. Analytics and Performance Metrics
        </h2>
        <p className="mb-4">
          YouGenie provides analytics and performance metrics for your YouTube
          channels and videos. This includes subscriber counts, view counts,
          video performance data, and calculated metrics such as Views Per Hour
          (VPH) and outlier scores. The outlier score helps identify videos that
          are performing exceptionally well or poorly compared to your channel's
          average. This data is collected and processed to provide you with
          insights into your channel's performance. We do not share this
          analytics data with third parties.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">
          15. AI-Powered Comment Analysis
        </h2>
        <p className="mb-4">
          YouGenie uses AI-powered tools to analyze comments on your videos and
          competitors' videos. This analysis helps generate insights, content
          ideas, and potential replies. The AI models used for this analysis do
          not store personal data from the comments. The analysis results are
          used solely to provide you with valuable insights and suggestions for
          your channel management and content strategy.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">
          16. Broadcast Campaigns
        </h2>
        <p className="mb-4">
          YouGenie allows users to create and manage broadcast campaigns to
          engage with their audience across multiple videos. We collect and
          store campaign-related data such as campaign names, messages, target
          videos, and performance metrics. This data is used to execute
          campaigns, track their effectiveness, and provide insights to users.
          Campaign data is treated with the same level of security and privacy
          as other user data and is not shared with third parties.
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">
          17. Thumbnail Generator
        </h2>
        <p className="mb-4">
          YouGenie provides an AI-powered Thumbnail Generator feature. When you
          use this feature, we collect and store the prompts you provide and the
          generated thumbnail images. These thumbnails are associated with your
          account and are used to provide you with a history of your generated
          thumbnails. We do not share these generated thumbnails or prompts with
          third parties. The AI models used for thumbnail generation do not
          store or learn from your personal data or generated images.
        </p>
        <p>
          By using YouGenie, you agree to the terms outlined in this Privacy
          Policy.
        </p>
      </div>
    </div>
  );
}
