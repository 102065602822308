import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import theme from "./theme";
import { ThemeProvider, CssBaseline, Box, useMediaQuery } from "@mui/material";

import LandingPage from "./components/pages/landing";
import SideBar from "./components/utils/sideBar";
import PopMenu from "./components/utils/popMenu";
import HomeScreen from "./components/screens/home";
import SettingsScreen from "./components/screens/settings";
import PrivacyPolicy from "./PrivacyPolicy";
import AnalyticsScreen from "./components/screens/analytics";
import CampaignsScreen from "./components/screens/campaigns";
import CampaignDetailsScreen from "./components/screens/campaigns/details";
import ThumbsScreen from "./components/screens/thumbs";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />}></Route>
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="app" element={<Application />}>
            <Route path="/app" element={<HomeScreen />}></Route>
            <Route path="/app/settings" element={<SettingsScreen />}></Route>
            <Route path="/app/analytics" element={<AnalyticsScreen />}></Route>
            <Route path="/app/campaigns" element={<CampaignsScreen />}></Route>
            <Route
              path="/app/campaigns/:id"
              element={<CampaignDetailsScreen />}
            ></Route>
            <Route path="/app/thumbs" element={<ThumbsScreen />}></Route>
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

function Application() {
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <div className="bg-[#0B0B0F] min-h-screen">
      <Box display="flex" flexDirection={desktop ? "row" : "column"}>
        {desktop ? <SideBar /> : <PopMenu />}
        <Outlet />
      </Box>
    </div>
  );
}
