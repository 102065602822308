import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../contexts/userContext";
import {
  CircularProgress,
  Divider,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
  Tooltip,
  InputAdornment,
  ButtonGroup,
  Button,
} from "@mui/material";
import { getDocs, query, orderBy, where } from "firebase/firestore";
import { Model } from "../../../models/model";
import ModelDb from "../../../db/modelDb";
import CustomButton from "../../utils/customButton";
import Functions from "../../../callable/functions";
import GenerationDb from "../../../db/generationDb";
import { Generation } from "../../../models/generation";
import { IconRefresh, IconLink } from "@tabler/icons-react";
import { trackEvent } from "../../../utils/analytics";

const ThumbsScreen: React.FC = () => {
  const user = useContext(UserContext);
  const [models, setModels] = useState<Model[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedModel, setSelectedModel] = useState<string>("");
  const [prompt, setPrompt] = useState<string>("");

  const [generating, setGenerating] = useState(false);
  const [generatedThumbnails, setGeneratedThumbnails] = useState<string[]>([]);
  const [generations, setGenerations] = useState<Generation[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [youtubeLink, setYoutubeLink] = useState<string>("");

  const [realismScale, setRealismScale] = useState<number>(3.5);

  useEffect(() => {
    const fetchModels = async () => {
      if (user) {
        setLoading(true);
        try {
          const modelDb = new ModelDb();
          const modelsQuery = query(
            modelDb.collection({ userId: user.id, modelId: "" }),
            orderBy("createdAt", "desc")
          );
          const querySnapshot = await getDocs(modelsQuery);
          const modelsData = querySnapshot.docs.map((doc) =>
            Model.fromFirestore(doc)
          );
          setModels(modelsData);
        } catch (error) {
          console.error("Error fetching models:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchModels();
  }, [user]);

  const handleGenerateThumbnail = async () => {
    if (!user || !selectedModel || !prompt) return;
    setGenerating(true);
    setGeneratedThumbnails([]);
    try {
      const functions = new Functions();
      const result = await functions.generateThumbnail({
        userId: user.id,
        modelIdentifier:
          models.find((m) => m.id === selectedModel)?.identifier || "",
        prompt: prompt,
        image: selectedImage || undefined,
        guidanceScale: realismScale,
      });
      if (result.data.success && result.data.outputUrls.length > 0) {
        setGeneratedThumbnails(result.data.outputUrls);
        setSelectedImage(null); // Reset selected image after generation

        // Fetch all generations after new thumbnails are generated
        fetchGenerations(selectedModel);
        trackEvent("thumbnail_generated");
      }
    } catch (error) {
      console.error("Error generating thumbnail:", error);
    } finally {
      setGenerating(false);
    }
  };

  const handleModelChange = async (event: SelectChangeEvent<string>) => {
    const selectedModelId = event.target.value as string;
    setSelectedModel(selectedModelId);
    const selectedModelData = models.find((m) => m.id === selectedModelId);
    setPrompt(selectedModelData?.basePrompt || "");

    // Fetch all generations when model changes
    fetchGenerations(selectedModelId);
  };

  const fetchGenerations = async (modelId: string) => {
    if (user && modelId) {
      try {
        const selectedModelData = models.find((m) => m.id === modelId);
        const generationDb = new GenerationDb();
        const generationsQuery = query(
          generationDb.collection({ userId: user.id, generationId: "" }),
          where("modelIdentifier", "==", selectedModelData?.identifier),
          orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(generationsQuery);
        const generationsData = querySnapshot.docs.map((doc) =>
          Generation.fromFirestore(doc)
        );
        setGenerations(generationsData);
      } catch (error) {
        console.error("Error fetching generations:", error);
      }
    }
  };

  const handleImageSelect = (imageUrl: string) => {
    setSelectedImage(imageUrl === selectedImage ? null : imageUrl);
  };

  const handleYoutubeLinkSubmit = () => {
    const videoId = extractYoutubeVideoId(youtubeLink);
    if (videoId) {
      const thumbnailUrl = `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`;
      setSelectedImage(thumbnailUrl);
      setYoutubeLink("");
    } else {
      // Handle invalid YouTube link
      console.error("Invalid YouTube link");
    }
  };

  const handleYoutubeLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;
    setYoutubeLink(url);
    const videoId = extractYoutubeVideoId(url);
    if (videoId) {
      const thumbnailUrl = `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`;
      setSelectedImage(thumbnailUrl);
    }
  };

  const extractYoutubeVideoId = (url: string): string | null => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  const handleRealismScaleChange = (newScale: number) => {
    setRealismScale(newScale);
  };

  const getModeLabel = (scale: number) => {
    switch (scale) {
      case 2:
        return "Realistic";
      case 2.5:
        return "Balanced Realistic";
      case 3:
        return "Balanced Creative";
      case 3.5:
        return "Highly Creative";
      default:
        return "Unknown";
    }
  };

  return (
    <div className="w-full min-h-screen bg-[#0B0B0F] text-white p-2 md:px-32 md:py-8 font-custom">
      <div className="flex justify-between items-center mb-4">
        <h4 className="text-base font-custom font-bold">Thumbnail Generator</h4>
      </div>
      <Divider
        sx={{
          borderColor: "#282828",
          borderBottomWidth: 2,
          marginBottom: 4,
        }}
      />

      {loading ? (
        <div className="flex justify-center items-center mt-8">
          <CircularProgress color="inherit" size={20} />
        </div>
      ) : models.length === 0 ? (
        <div className="text-center mt-8">
          <p className="text-gray-400">
            No models found. Please create a model to get started.
          </p>
        </div>
      ) : (
        <div className="space-y-4">
          <Select
            value={selectedModel}
            onChange={handleModelChange}
            displayEmpty
            fullWidth
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: "#1E1E1E",
                  color: "white",
                  borderRadius: "8px",
                },
              },
            }}
            sx={{
              color: "white",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#282828",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#484848",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#FF0000",
              },
              "& .MuiSelect-icon": {
                color: "white",
              },
            }}
          >
            <MenuItem
              value=""
              disabled
              style={{ color: "rgba(255, 255, 255, 0.7)" }}
            >
              Select a model
            </MenuItem>
            {models.map((model) => (
              <MenuItem
                key={model.id}
                value={model.id}
                sx={{
                  borderBottom: "1px solid #282828",
                  "&:last-child": {
                    borderBottom: "none",
                  },
                }}
              >
                <div>
                  <div className="font-bold">{model.name}</div>
                  <div className="text-sm text-gray-400">
                    {model.description}
                  </div>
                </div>
              </MenuItem>
            ))}
          </Select>
          <TextField
            fullWidth
            variant="outlined"
            label="Prompt"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            multiline
            rows={4}
            placeholder={
              models.find((m) => m.id === selectedModel)?.basePrompt ||
              "Enter your prompt here"
            }
            InputProps={{
              style: { color: "white" },
            }}
            InputLabelProps={{
              style: { color: "rgba(255, 255, 255, 0.7)" },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#282828",
                },
                "&:hover fieldset": {
                  borderColor: "#484848",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#FF0000",
                },
              },
            }}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div className="flex flex-col space-y-2">
                <p className="text-sm text-gray-400">
                  Reference Image (Optional)
                </p>
                <div className="flex items-center space-x-2">
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Paste YouTube video URL for thumbnail reference"
                    value={youtubeLink}
                    onChange={handleYoutubeLinkChange}
                    InputProps={{
                      style: { color: "white" },
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconLink size={20} className="text-gray-400" />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#282828",
                        },
                        "&:hover fieldset": {
                          borderColor: "#484848",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#FF0000",
                        },
                      },
                    }}
                  />
                  {selectedImage && (
                    <IconRefresh
                      className="cursor-pointer text-gray-400 hover:text-white"
                      onClick={() => {
                        setSelectedImage(null);
                        setYoutubeLink("");
                      }}
                      size={20}
                    />
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="flex flex-col space-y-2">
                <p className="text-sm text-gray-400">Realism Scale</p>
                <ButtonGroup
                  variant="outlined"
                  aria-label="realism scale button group"
                  fullWidth
                  sx={{
                    height: "56px", // Adjust this value to match your TextField height
                  }}
                >
                  {[2, 2.5, 3, 3.5].map((scale) => (
                    <Button
                      key={scale}
                      onClick={() => handleRealismScaleChange(scale)}
                      variant={
                        realismScale === scale ? "contained" : "outlined"
                      }
                      sx={{
                        flex: 1,
                        height: "100%",
                      }}
                    >
                      {getModeLabel(scale)}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
            </Grid>
          </Grid>

          {selectedImage && (
            <div className="flex items-center space-x-2">
              <img
                src={selectedImage}
                alt="Selected thumbnail"
                className="w-32 h-18 object-cover rounded"
              />
            </div>
          )}

          <div className="flex items-center justify-between space-x-4">
            <p className="text-sm text-gray-400">
              Tip: Use a previously generated image or a YouTube thumbnail as a
              reference to guide the AI in creating similar styles or
              compositions.
            </p>
            <CustomButton
              onClick={handleGenerateThumbnail}
              disabled={!selectedModel || !prompt || generating}
            >
              {generating ? "Generating..." : "Generate"}
            </CustomButton>
          </div>
        </div>
      )}
      {generations.length > 0 && (
        <div className="mt-8">
          <h4 className="text-base font-custom font-bold mb-4">
            All Generated Thumbnails
          </h4>
          <Grid container spacing={2}>
            {generations.map((generation, index) => (
              <Grid item xs={12} sm={6} md={3} key={generation.id}>
                <Tooltip
                  title={
                    <div>
                      <p className="font-custom">{generation.prompt}</p>
                    </div>
                  }
                  arrow
                  placement="top"
                >
                  <div
                    className={`aspect-w-16 aspect-h-9 cursor-pointer ${
                      generation.outputUrl === selectedImage
                        ? "ring-2 ring-red-500 rounded-lg"
                        : ""
                    }`}
                    onClick={() => handleImageSelect(generation.outputUrl)}
                  >
                    <img
                      src={generation.outputUrl}
                      alt={`Generated Thumbnail ${index + 1}`}
                      className="object-cover w-full h-full rounded-lg"
                    />
                  </div>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </div>
  );
};

export default ThumbsScreen;
