import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconMenu,
  IconX,
  IconMessages,
  IconLogout,
  IconSettings,
  IconGraph,
  IconSpeakerphone,
  IconPhoto,
} from "@tabler/icons-react";
import { getAuth, signOut } from "firebase/auth";

export default function PopMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    signOut(getAuth());
    navigate(`/`);
  };

  const handleOnClick = (path: string) => {
    navigate(`/app${path}`);
    toggleMenu();
  };

  return (
    <div className="md:hidden relative" style={{ backgroundColor: "#0B0B0F" }}>
      <button onClick={toggleMenu} className="text-white p-4">
        {isOpen ? <IconX size={20} /> : <IconMenu size={20} />}
      </button>
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full flex flex-col items-center py-6 space-y-8 z-50"
          style={{ backgroundColor: "#0B0B0F" }}
        >
          <button
            onClick={() => handleOnClick("")}
            className="flex items-center justify-center"
          >
            <img src="/images/logo.svg" alt="Logo" className="h-8" />
          </button>
          <button
            onClick={() => handleOnClick("")}
            className="flex items-center justify-center"
          >
            <IconMessages size={20} className="text-white" />
          </button>
          <button
            onClick={() => handleOnClick("/analytics")}
            className="flex items-center justify-center"
          >
            <IconGraph size={20} className="text-white" />
          </button>
          <button
            onClick={() => handleOnClick("/campaigns")}
            className="flex items-center justify-center"
          >
            <IconSpeakerphone size={20} className="text-white" />
          </button>
          <button
            onClick={() => handleOnClick("/thumbs")}
            className="flex items-center justify-center"
          >
            <IconPhoto size={20} className="text-white" />
          </button>
          <button
            onClick={() => handleOnClick("/settings")}
            className="flex items-center justify-center"
          >
            <IconSettings size={20} className="text-white" />
          </button>
          <button
            onClick={handleLogout}
            className="flex items-center justify-center"
          >
            <IconLogout size={20} className="text-white" />
          </button>
        </div>
      )}
    </div>
  );
}
